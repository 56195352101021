import { addFoldersToFolderTableMutator } from '../../mutators/addFoldersToFolderTableMutator';
import type { MailFolder, MailFolder as Schema_MailFolder } from 'owa-graph-schema';
import { updateFolderInformation } from '../../mutators/updateFolderInformation';
import { shouldShowFolder } from 'owa-folders-data-utils';

export function updateFolderTable(
    displayName: string | undefined,
    rootFolder: Schema_MailFolder,
    allFolders: Schema_MailFolder[],
    createOrGetFolder_0: (folder: Schema_MailFolder) => MailFolder
) {
    const folders: {
        [id: string]: MailFolder;
    } = {};

    // Convert root folder to mobx type
    const root = (folders[rootFolder.id] = createOrGetFolder_0(rootFolder));

    // Convert all folders to mobx type
    const folderIds: string[] = [];
    for (let i = 0; i < allFolders.length; i++) {
        const rawFolder = allFolders[i];
        const folderId = rawFolder.id;
        const distinguishedFolderType = rawFolder.distinguishedFolderType;
        if (shouldShowFolder(distinguishedFolderType, rawFolder.mailboxInfo)) {
            folders[folderId] = createOrGetFolder_0(rawFolder);
            folderIds.push(folderId);
        }
    }

    updateFolderInformation(root, displayName, folders, folderIds);

    // Store folders in folder table
    addFoldersToFolderTableMutator(folders);
}
