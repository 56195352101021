import loc from 'owa-localize';
import { foldersDiagnosticDataDescription } from './foldersDiagnosticStrings.locstring.json';
import { lazyRegisterDataCollectorIfNotRegistered } from 'diagnostics-data-collection';
import type { FolderHierarchyResult } from 'owa-graph-schema';
import { ObservableMap } from 'mobx';

export async function registerDataCollector() {
    const getFoldersDataCollector = {
        dataCollectionAction: getFoldersDataCollectionAction,
        name: 'FolderData',
        description: loc(foldersDiagnosticDataDescription),
    };
    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(getFoldersDataCollector);
}

const foldersLog = new ObservableMap<string, string>();

export function setFoldersDataCollectionInformation(
    mailboxIdentifier: string,
    result: FolderHierarchyResult | Error
) {
    foldersLog.set(mailboxIdentifier, JSON.stringify({ mailboxIdentifier, result }));

    // Register the data collector to upload the folder hierarchy response to logs if user reports an issue
    registerDataCollector();
}

function getFoldersDataCollectionAction(): Promise<string> {
    return Promise.resolve(JSON.stringify(foldersLog));
}
