import type { MailFolder } from 'owa-graph-schema';
import { onFolderHierarchyResponse } from '../actions/onFolderHierarchyResponse';
import { isStringNullOrWhiteSpace } from 'owa-localize';
import { setFolderTreeData } from '../mutators/setFolderTreeData';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import { addFolderToDefaultFolderMappings } from 'owa-session-store';
import { orchestrator } from 'satcheljs';
import { createOrGetFolder } from './helpers/createOrGetFolder';
import { updateFolderTable } from './helpers/updateFolderTable';
import getFolderTable from '../selectors/getFolderTable';
import { isPstFileAccountInfraEnabled } from 'owa-account-source-list/lib/flights';
import { isCoprincipalMailbox } from 'owa-account-source-list-store';

/**
 * Orchestrator that updates the folder table and sets-up parent child folder relations
 * for gql folder hierarchy response.
 */
export const onFolderHierarchyResponseOrchestrator: (
    actionMessage: ReturnType<typeof onFolderHierarchyResponse>
    /* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> Do not register an orchestrator with an action that is defined in the same package */
) => void | Promise<void> = orchestrator(onFolderHierarchyResponse, actionMessage => {
    const {
        displayName,
        folders,
        folderTreeIdentifier,
        includesLastItemInRange,
        offset,
        rootFolder,
        mailboxInfo,
        isCustomSorted,
    } = actionMessage;

    const createOrGetFolder_0 = (folder: MailFolder) => {
        return createOrGetFolder(folder);
    };

    updateFolderTable(displayName, rootFolder, folders, createOrGetFolder_0);

    setFolderTreeData(
        folderTreeIdentifier,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        getFolderTable().get(rootFolder.id)!, // Update folder table has added root to the table
        FolderTreeLoadStateEnum.Loaded,
        !includesLastItemInRange, // HasMoreData = !IncludesLastItemInRange
        offset,
        mailboxInfo,
        isCustomSorted
    );

    // Ensure we have up-to-date folder id<->DistinguishedFolderId mappings for primary mailbox types
    // (UserMailbox or PstFile) and archive mailboxes
    const mailboxType = rootFolder.mailboxInfo.type;
    const rootFolderId = rootFolder.id;
    const isPrimaryMailboxType = isPstFileAccountInfraEnabled()
        ? isCoprincipalMailbox(rootFolder.mailboxInfo)
        : mailboxType === 'UserMailbox';
    if (isPrimaryMailboxType || mailboxType === 'ArchiveMailbox') {
        for (let i = 0; i < folders.length; i++) {
            const rawFolder = folders[i];
            const folderId = rawFolder.id;
            const distinguishedFolderType = rawFolder.distinguishedFolderType;
            const folderMailboxInfo = rawFolder.mailboxInfo;

            const shouldAdd =
                (isPrimaryMailboxType || rawFolder.parentFolderId == rootFolderId) &&
                !isStringNullOrWhiteSpace(distinguishedFolderType);

            if (shouldAdd && distinguishedFolderType) {
                addFolderToDefaultFolderMappings(
                    distinguishedFolderType,
                    folderId,
                    folderMailboxInfo
                );
            }
        }

        if (rootFolder.distinguishedFolderType) {
            addFolderToDefaultFolderMappings(
                rootFolder.distinguishedFolderType,
                rootFolderId,
                rootFolder.mailboxInfo
            );
        }
    }
});
