import type { MailboxInfo } from 'owa-client-types';
import type {
    M365UserMailboxAccountSource,
    M365ArchiveMailboxAccountSource,
} from 'owa-account-source-list-store';
import type SessionSettings from 'owa-service/lib/contract/SessionSettingsType';
import {
    buildM365ArchiveMailboxAccountSource,
    buildOnlineArchiveMailboxSmtp,
} from 'owa-account-source-list/lib/utils/accountSourceBuilder';
import {
    accountSourceDataTypeChecker,
    getAccountBySourceId,
    LoadState,
    setArchiveAccounts,
} from 'owa-account-source-list-store';
import { isRemovedAccount, type RemovedAccount } from 'owa-account-source-list-types';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';
import { MailboxInfoError } from '../MailboxInfoError';

/**
 * Builds the account source for the archive mailbox
 * @param account The account source the archive is to be added to
 * @param archiveMailboxGuid Guid of the archive mailbox to add
 * @param organizationDomain Organization domain of the account
 * @param archiveDisplayName Optionally specifies the display name of the archive mailbox
 */
function makeArchiveMailboxAccountSource(
    account: M365UserMailboxAccountSource | RemovedAccount,
    archiveMailboxGuid: string,
    organizationDomain: string,
    archiveDisplayName?: string
): M365ArchiveMailboxAccountSource {
    const archiveMailboxSmtp = buildOnlineArchiveMailboxSmtp(
        archiveMailboxGuid,
        organizationDomain
    );

    return buildM365ArchiveMailboxAccountSource(
        archiveMailboxGuid,
        archiveDisplayName ?? archiveMailboxSmtp,
        archiveMailboxSmtp,
        account.mailboxInfo.userIdentity,
        account.persistenceId
    );
}

/**
 * Adds the online archive mailbox to the account source
 * @param account The account source the archive is to be added to
 * @param archiveMailbox account source for the archice mailbox to be added
 */
function addArchiveMailboxToAccount(
    account: M365UserMailboxAccountSource,
    archiveMailbox: M365ArchiveMailboxAccountSource
) {
    const existingArchives: M365ArchiveMailboxAccountSource[] = [];
    for (const archive of account.archiveSources.sources) {
        if (accountSourceDataTypeChecker.isM365ArchiveMailbox(archive)) {
            existingArchives.push(archive);
        }
    }

    setArchiveAccounts(account.sourceId, LoadState.Loaded, [...existingArchives, archiveMailbox]);
}

/**
 * Searches the account an either finds or adds the archive mailbox with the given mailbox guid
 * @param account Specified the account
 * @param sessionSettings Contains the session settings information for the account
 * @param archiveMailboxGuid Specifies the guid of the archive mailbox to search for or add
 * @param archiveDisplayName Optionally specifies the display name of the archive mailbox
 * @returns MailboxInfo for the archive mailbox, or will throw an exception if a failure happens
 */
export default function findOrMakeArchiveMailboxInfoForAccount(
    account: M365UserMailboxAccountSource | RemovedAccount,
    sessionSettings: RecursiveReadOnly<SessionSettings>,
    archiveMailboxGuid: string,
    archiveDisplayName?: string
): MailboxInfo {
    const organizationDomain = sessionSettings.OrganizationDomain;
    if (!organizationDomain) {
        throw new MailboxInfoError('Organization domain not found for account');
    }

    // We build the archive mailbox account source that would need to be added, this will get us the sourceId
    // for the archive mailbox which we can use to find if it already exists or needs to be added
    const archiveMailbox = makeArchiveMailboxAccountSource(
        account,
        archiveMailboxGuid,
        organizationDomain,
        archiveDisplayName
    );

    if (isRemovedAccount(account)) {
        // Mark the MailboxInfo as coming from a removed account
        return { ...archiveMailbox.mailboxInfo, isRemoved: true, mailboxRank: 'Resource' };
    }

    // Add the MailboxInfo to the account if it doesn't already exist
    let existing = getAccountBySourceId(archiveMailbox.sourceId);
    if (!existing) {
        addArchiveMailboxToAccount(account, archiveMailbox);
        existing = getAccountBySourceId(archiveMailbox.sourceId);
        if (!existing) {
            throw new MailboxInfoError('Failed to find the added archive');
        }
    }

    return existing.mailboxInfo;
}
