import { mutatorAction } from 'satcheljs';
import type { MailFolder } from 'owa-graph-schema';
import getFolderTable from '../selectors/getFolderTable';

// We are using a mutator action because when we retrieve folders from the folder table to update the folder information(DisplayName, childrenFolderIds), we are changing
// stored observable values.
export const updateFolderInformation = mutatorAction(
    'updateFolder',
    (
        folder: MailFolder,
        displayName: string | undefined,
        folders: {
            [id: string]: MailFolder;
        },
        folderIds: string[]
    ) => {
        if (displayName) {
            folder.displayName = displayName; // The passed in folder, if it existed in the table, is a mobx observable.
        }

        folderIds.forEach(folderId => {
            const childFolder = folders[folderId];
            // Parent maybe already fetched and did not come as part of this request, so get from store
            const parent =
                folders[childFolder.parentFolderId] ||
                getFolderTable().get(childFolder.parentFolderId); // The folder retreived from getFolderTable is an observable.
            if (parent?.childFolderIds && parent.childFolderIds.indexOf(folderId) == -1) {
                parent.childFolderIds.push(folderId);
            }
        });
    }
);
